import { Component } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  mobileMenuOpen = false;
  activeMenu: any = null;

  MENUITEMS = [
    {
      title: "Home",
      path: "/",
      type: "link",
      active: false,
    },
    {
      title: "About us",
      type: "sub",
      active: false,
      children: [
        { path: "/about-us", title: "about us", type: "link" },
        { path: "/vision-mission", title: "vision mission", type: "link" },
        {
          title: "committee",
          type: "sub",
          children: [
            {
              title: "College Committee",
              type: "link",
              path: "/college-committee",
            },
          ],
        },
        { path: "/mentors", title: "Mentors", type: "link" },
        { path: "/corporates", title: "Corporates", type: "link" },
      ],
    },
    {
      title: "Programme",
      type: "sub",
      active: false,
      children: [
        {
          title: "UG Programme",
          type: "sub",
          children: [
            { path: "/course/BBA", title: "BBA", type: "link" },
            { path: "/course/BBA-Aviation", title: "BBA Aviation", type: "link" },
          ],
        },
        {
          title: "PG Programme",
          type: "sub",
          children: [
            { path: "/course/MBA-4_0", title: "MBA 4.0", type: "link" },
            { path: "/course/MBA-international", title: "MBA international", type: "link" },
            { path: "/course/MBA-Global", title: "MBA Global", type: "link" },
            { path: "/course/MBA-Advance", title: "MBA Advance", type: "link" },
          ],
        },
        {
          title: "Certification Programme",
          type: "sub",
          children: [
            { path: "/course/CBAP", title: "CBAP", type: "link" },
            { path: "/course/CFM", title: "CFM", type: "link" },
            { path: "/course/CHRAP", title: "CHRAP", type: "link" },
            { path: "/course/CAT", title: "CAT", type: "link" },
            { path: "/course/CMAP", title: "CMAP", type: "link" },
            { path: "/course/CLM", title: "CLM", type: "link" },
            { path: "/course/CAI", title: "CAI", type: "link" },
            { path: "/course/CDM", title: "CDM", type: "link" },
            { path: "/course/CBA", title: "CBA", type: "link" },
            { path: "/course/CMIS", title: "CMIS", type: "link" },
          ],
        },
      ],
    },
    {
      title: "Admission",
      path: "/admission",
      type: "link",
      active: false,
    },
    {
      title: "Life @ONESB",
      path: "/student-life",
      type: "sub",
      active: false,
      children: [
        { path: "/student-life", title: "student life", type: "link" },
      ],
    },
    {
      title: "Gallery",
      type: "sub",
      active: false,
      children: [
        { path: "/onam", title: "onam", type: "link" },
        { path: "/sports", title: "Sports", type: "link" },
        { path: "/library", title: "Library", type: "link" },
        { path: "/annual", title: "Annual Day", type: "link" },
        { path: "/kannadostva", title: "Kannadostva", type: "link" },
      ],
    },
    {
      title: "FINISHING SCHOOL",
      path: "/finishing-school",
      type: "link",
      active: false,
    },
    {
      title: "CENTER OF EXCELLENCE",
      path: "https://apply.onesb.edu.in/",
      type: "extLink",
      active: false,
    },
    {
      title: "PLACEMENTS",
      type: "sub",
      active: false,
       path: "/placements-details",
    },
  ];
  

  toggleMobileMenu() {
    this.mobileMenuOpen = !this.mobileMenuOpen;
  }

  setActiveMenu(menuItem: any) {
    this.activeMenu = menuItem;
  }

  clearActiveMenu(menuItem: any) {
    if (this.activeMenu === menuItem) {
      this.activeMenu = null;
    }
  }
}
